import lightstep from "lightstep-tracer/browser";
import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { getPortalConfig } from "core/portal-config";
import { Span, SpanOptions } from "opentracing";
import { Me, PortalConfig } from "@zeos/platform";
import { convertSpanTimeOriginToServer, getCurrentTime } from "./utils";
import { ZBaseTracer } from "./types";
import {
  getIsZalandoEmployee,
  getTracerComponentName,
  COMMON_TAGS
} from "./tag-utils";

/**
 * ZTracer extends the lightstep Tracer by adding tags to the created span
 */
export default class ZTracer extends lightstep.Tracer implements ZBaseTracer {
  private me: Me | undefined = undefined;
  private tracingConsentEnabledPromise: Promise<boolean> | undefined;

  /**
   * Set tracing consent enabled promise
   *
   * @param tracingConsentEnabledPromise Tracing consent enabled promise
   */
  setTracingConsentEnabledPromise(
    tracingConsentEnabledPromise: Promise<boolean> | undefined
  ): void {
    this.tracingConsentEnabledPromise = tracingConsentEnabledPromise;
  }
  /* eslint-disable no-underscore-dangle */
  /**
   * creates new span and attach tags to the span by default (module name, pathname, user_agent)
   * and (is_zalando_employee) if me object does exist
   * @param {string} name Tracer name
   * @param {SpanOptions} fields SpanOptions
   */
  protected _startSpan(name: string, fields: SpanOptions): SpanImp {
    let portalConfig: PortalConfig | null = null;
    try {
      portalConfig = getPortalConfig();
    } catch (e) {}

    const { apps, monitoring } = portalConfig || {};

    // creates new span
    const span = super._startSpan(name, fields) as SpanImp;

    /**
     * updates the span start time with {startTime} if provided in params
     * or the current time synchronized with server time origin as default
     */
    if (fields.startTime !== undefined) {
      span.setBeginMicros(fields.startTime);
    } else {
      span.setBeginMicros(convertSpanTimeOriginToServer(getCurrentTime()));
    }

    /**
     * overwrite the span finish method to update the span end time with {finishTime} if provided in params
     * or the current time synchronized with the server time origin as default
     */
    const finishBindedSpan = span.finish.bind(span);

    /**
     * Integrate finish with lightstep consent so that we only finish
     * and send span if consent is available.
     *
     * This is an easy way to enable/disable lightstep tracking based on
     * consent mgmt status without the need to refactor to make the whole
     * code async.
     *
     * @param finishTime
     */
    span.finish = (finishTime?: number) => {
      const endTime =
        finishTime !== undefined
          ? finishTime
          : convertSpanTimeOriginToServer(getCurrentTime());

      // if there is a single span where end time comes out
      // to be less than start time, all the spans will fail.
      // Hence, Ensure that end micro is always more than start micro.
      span.setEndMicros(Math.max(endTime, span.beginMicros()));

      return this.tracingConsentEnabledPromise
        ? this.tracingConsentEnabledPromise.then(tracingConsentEnabled => {
            if (tracingConsentEnabled) {
              finishBindedSpan();
            }
          })
        : Promise.resolve();
    };

    /* eslint-enable */
    span.addTags({
      "span.kind": "client",
      user_agent: window.navigator.userAgent,
      "http.path": window.location.pathname
    });

    if (apps) {
      // if portal accessible apps are available, set the current active app name in span tags
      span.setTag("component", getTracerComponentName(apps));
    }

    const flowId = monitoring?.tracing?.baggage?.flow_id;
    if (flowId) {
      span.setTag("flow_id", flowId);
    }

    this.setMeTagsToSpan(span);

    return span;
  }

  /**
   * Adds me related tags to span
   *
   * @param span Span to add the tags to
   */
  setMeTagsToSpan(span: Span): void {
    if (this.me) {
      span.addTags({
        [COMMON_TAGS.ZALANDO_EMPLOYEE]: getIsZalandoEmployee(this.me)
      });
    }
  }

  /**
   * keep an instance of {me} object in ZTracer
   * @param me Me
   */
  setMe(me: Me): void {
    this.me = me;
  }

  /**
   * Get me
   *
   * @returns Me Object
   */
  getMe(): Me | undefined {
    return this.me || undefined;
  }

  /**
   * Static value to determine tracer
   */
  // eslint-disable-next-line class-methods-use-this
  getTracerName(): string {
    return "ztracer";
  }
}
