import { LifeCycles, ActivityFn, LifeCycleFn } from "single-spa";
import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { Tracer } from "opentracing";
import {
  AppConfig,
  AppError,
  AppType,
  PortalAppManifest
} from "@zeos/platform";
import store from "core/store";
import {
  appBootstrappingStarted,
  appBootstrappingFinished,
  appLoadingFailed,
  appMountingStarted,
  appMountingFinished,
  appUnmountingStarted,
  appUnmountingFinished,
  appLoadingStarted
} from "core/store/app-status/app-status.actions";
import { decorateWithHooks } from "core/hooks-decorator";

import { loadAppManifest, isValidAppManifest } from "core/app-manifest";
import { getActiveAppName, isPortalLoadError } from "util/routing";
import {
  getTracer,
  hasPerformanceApi,
  setFirstLoadedApp,
  getAppSpan,
  clearAppSpan,
  getRootSpan
} from "./monitoring/tracing";
import { reduxHook } from "./store/phase-hook";
import { tracingHook } from "./monitoring/tracing/phase-hook";
import { getPortalLoadError } from "./portal-config";
import { createNavigationTree, NavigationTree } from "../util/navigation-tree";
import { unmountHook } from "./parcels";
import { captureError } from "@zeos/platform";
import { getOverride } from "./importOverrides";

interface PortalApplication {
  manifest: PortalAppManifest;
  name: string;
  applicationOrLoadingFn(): Promise<LifeCycles>;
  activityFn: ActivityFn;
  label: string;
  type: AppType;
  pathPrefix: string;
}

/**
 * Decorate single-spa portal application lifeCycle functions
 * @param {string} appName
 * @param {LifeCycles} lifeCycles
 */
function decorateApplicationLifeCycles<T>(
  appName: string,
  appLabel: string,
  lifeCycles: LifeCycles<T>,
  tracer: Tracer
) {
  return {
    ...lifeCycles,
    bootstrap: decorateWithHooks(
      "bootstrap_app",
      appName,
      lifeCycles.bootstrap as LifeCycleFn<T>,
      [
        reduxHook(
          store,
          appBootstrappingStarted(appName, appLabel),
          appBootstrappingFinished(appName, appLabel),
          (e: any) => appLoadingFailed(appName, appLabel, e)
        ),
        // do not close app span in bootstrap success phase because
        // we need the span to continue in `mount` phase
        tracingHook(tracer, ["phase"], ["phase", "app", "server"])
      ]
    ),
    mount: decorateWithHooks(
      "mount_app",
      appName,
      lifeCycles.mount as LifeCycleFn<T>,
      [
        reduxHook(
          store,
          appMountingStarted(appName, appLabel),
          appMountingFinished(appName, appLabel),
          (e: any) => appLoadingFailed(appName, appLabel, e)
        ),
        // close app and server spans in mount phase
        tracingHook(
          tracer,
          ["phase", "app", "server"],
          ["phase", "app", "server"]
        )
      ]
    ),
    unmount: decorateWithHooks(
      "unmount_app",
      appName,
      lifeCycles.unmount as LifeCycleFn<T>,
      [
        unmountHook,
        reduxHook(
          store,
          appUnmountingStarted(appName, appLabel),
          appUnmountingFinished(appName, appLabel),
          (e: any) => appLoadingFailed(appName, appLabel, e)
        )
      ]
    )
  };
}

function updateLoadingStatus(path: string, activeApp?: string) {
  window.LoadedPath = path;
  window.LoadedStatus = activeApp ? 200 : 404;
}

/**
 * Create a single spa portal application from app configuration
 * Tries to fetch app manifest from remote manifest location to know what js file
 * to "import" with the help of SystemJS
 *
 * @param {AppConfig} appConfig
 * @param {NavigationTree} navigationTree
 * @return {PortalApplication}
 */
export function createPortalApp(
  appConfig: AppConfig,
  navigationTree: NavigationTree
): PortalApplication {
  const { name, label, pathPrefix, type } = appConfig;

  const tracer = getTracer("web_portal");

  const app = {
    name,
    label,
    type,
    pathPrefix,
    manifest: {} as PortalAppManifest,
    activityFn: (location: Location): boolean => {
      // Entry point on every transition of URL
      const activeApp = getActiveAppName(navigationTree, location);
      updateLoadingStatus(location.pathname, activeApp);

      if (isPortalLoadError(location)) {
        store.dispatch(
          appLoadingFailed("portal", "server", getPortalLoadError())
        );
      }
      return activeApp === name;
    },
    applicationOrLoadingFn: async () => {
      try {
        store.dispatch(appLoadingStarted(name, label));

        const appSpan = getAppSpan(tracer);
        // set {first_load} tag to true since the app bundle will be loaded
        appSpan.setTag("first_load", true);

        const loadAppBundleSpan = tracer.startSpan("load_app_bundle", {
          childOf: appSpan
        }) as SpanImp;
        let loadedModule: LifeCycles;

        if (hasPerformanceApi()) {
          window.performance.mark("load-app-bundle");
        }

        // Internal try/catch block is necessary
        // because we want to attach the error message
        // to load_app_bundle span. This allows us
        // to collect load_app_bundle span when there is an error
        // and still retain the existing error flow
        try {
          const appManifest = await loadAppManifest(appConfig);

          if (!isValidAppManifest(appManifest)) {
            throw new Error(`invalid "${name}" app manifest file`);
          }

          // assigning app manifest for other usage (eg. i18next config)
          // achtung! do not reassign to avoid losing the reference that other might be using
          Object.assign(app.manifest, appManifest);
          const existingOverride =
            window?.importMapOverrides?.getOverrideMap?.()?.imports?.[
              appManifest.moduleName
            ];
          const jsPath = existingOverride ?? appManifest?.files?.js;
          loadedModule = await window.System.import(jsPath);
        } catch (error) {
          loadAppBundleSpan.setTag("error", true);
          loadAppBundleSpan.log({ error: (error as Error).message });

          throw error;
        } finally {
          loadAppBundleSpan.finish();
        }

        return decorateApplicationLifeCycles(name, label, loadedModule, tracer);
      } catch (error) {
        console.error(
          `Failed to load "${name}" app, caused by ${(error as Error).message}`
        );
        // attach the error tag to app span and finish the app span and clear the cached one
        const appSpan = getAppSpan(tracer);
        appSpan.setTag("error", true);
        appSpan.finish();
        clearAppSpan();
        // attach error tag to the whole server span to highlight the error
        // and finish the server span
        const rootSpan = getRootSpan(tracer);
        rootSpan.setTag("error", true);
        rootSpan.finish();

        // update the boolean indicator of loading first app so
        // new app spans will be created in new tracers
        setFirstLoadedApp();
        const isApplicationOverriden = Boolean(getOverride(name));
        !isApplicationOverriden &&
          captureError(error as Error, { tags: { severity: "critical" } });

        store.dispatch(
          appLoadingFailed(
            name,
            label,
            new AppError(`Failed to load "${name}" application`, {})
          )
        );
        throw error;
      }
    }
  };

  return app;
}

/**
 * Create single-spa portal applications given apps configurations
 *
 * @param {Array<AppConfig>} appsConfigs
 */
export function createPortalApps(
  appsConfigs: Array<AppConfig>
): Array<PortalApplication> {
  const navigationTree = createNavigationTree(appsConfigs);
  /**
   * TODO: once this ticket is merged: https://github.bus.zalan.do/merchant-platform/quasar-overview/issues/1420
   *       use getAppsWithPathPrefix from "util/apps" instead of `filter` to remove code redundancy
   */
  return appsConfigs
    .filter(app => Boolean(app.pathPrefix))
    .map(appConfig => createPortalApp(appConfig, navigationTree));
}
