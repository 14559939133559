import { getPortalConfig, PortalConfig } from "core/portal-config";
import {
  tracingConfigEnabled,
  tracingConsentEnabled
} from "core/trackers/tracker-status";

import { ZBaseTracer } from "./types";
import { ZNoopTracer } from "./ZNoopTracer";
import ZTracer from "./ZTracer";

type TracingConfig = {
  accessToken?: string;
  componentName?: string;
  enabled: boolean;
  debug?: boolean;
  tracingConsentEnabledPromise?: Promise<boolean>;
};

let tracingConfig: TracingConfig | undefined;

/**
 * This function is mainly used for tests
 */
export const resetTracingConfig = (): void => {
  tracingConfig = undefined;
};

/**
 * grabs the global portal config, then populates the tracing config
 * with environmental tracing variables
 *
 * @returns TracingConfig
 */
export const getTracingConfig = (): TracingConfig => {
  if (!tracingConfig) {
    let portalConfig: PortalConfig | null = null;
    try {
      portalConfig = getPortalConfig();
    } catch (e) {}

    if (!portalConfig) {
      return { enabled: false };
    }

    const { monitoring } = portalConfig || {};

    const isTracingConfigEnabled = tracingConfigEnabled(monitoring);
    const tracingConsentEnabledPromise = tracingConsentEnabled();

    if (!isTracingConfigEnabled) {
      return { enabled: false };
    }
    const {
      lightstepAccessToken: accessToken,
      componentName,
      debug
    } = monitoring.tracing;

    tracingConfig = {
      accessToken,
      componentName,
      tracingConsentEnabledPromise: tracingConsentEnabledPromise,
      debug,
      // This should always be true as we exit the function if tracing is disabled
      enabled: true
    };
  }

  return tracingConfig;
};

const tracers: Record<string, ZBaseTracer> = {};

function createTracer(config: TracingConfig): ZBaseTracer {
  const { accessToken, componentName, debug, tracingConsentEnabledPromise } =
    config;

  const zTracer = new ZTracer({
    access_token: accessToken!,
    component_name: componentName ?? "web-portal",
    verbosity: debug ? 4 : 0,
    disable_clock_skew_correction: true
  });

  zTracer.setTracingConsentEnabledPromise(tracingConsentEnabledPromise);

  return zTracer;
}
/**
 * finds a tracer in the tracers object if it exists or returns a
 * ZTracer build with the config
 *
 * @param name string prop name of tracer in tracers object
 */
export const getTracer = (name: string): ZBaseTracer => {
  if (!(name in tracers)) {
    const config = getTracingConfig();

    tracers[name] = config.enabled ? createTracer(config) : new ZNoopTracer();
  }

  return tracers[name];
};
