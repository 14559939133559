import type { Toggle } from "@zeos/platform-types";
import type { AppConfigWithScopes, ParcelConfigWithScopes } from "typings";

type FilterableConfigs = AppConfigWithScopes | ParcelConfigWithScopes;

export const requiredFeatureToggleFiltering = (enabledToggles: Toggle[] = []) => {
  const normalizedTogglesList: string[] = enabledToggles.map((toggle: Toggle) => toggle.key);
  return ((app: FilterableConfigs): boolean => {
    if (!app.requiredFeatureToggle) {
      return true;
    }
    return normalizedTogglesList.includes(app.requiredFeatureToggle);
  });
}

export const requiredScopesFiltering = (userScopes: string[] = []) => {
  return ((app: FilterableConfigs): boolean => {
    if (!app.requiredScope) {
      return true;
    }
    return userScopes.includes(app.requiredScope);
  });
}

export const filterByAccess = <T extends FilterableConfigs>(apps: T[], enabledToggles: Toggle[], userScopes: string[]): T[] => {
  const scopeFilter = requiredScopesFiltering(userScopes);
  const toggleFilter = requiredFeatureToggleFiltering(enabledToggles);
  return apps.filter((app) => scopeFilter(app) && toggleFilter(app));
}
