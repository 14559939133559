import SpanContextImp from "lightstep-tracer/lib/imp/span_context_imp";
import { getPortalConfig, PortalConfig } from "../../portal-config";

export interface TraceCarrierObject {
  traceId: string;
  spanId: string;
  sampled?: boolean;
  baggage?: Record<string, any>;
  [propKey: string]: any;
}

export const extractFromTraceCarrierObject = (
  carrier: TraceCarrierObject
): SpanContextImp | undefined => {
  if (
    !(
      carrier &&
      typeof carrier === "object" &&
      "traceId" in carrier &&
      "spanId" in carrier
    )
  ) {
    return undefined;
  }

  const sampled = carrier.sampled !== undefined ? carrier.sampled : true;

  const context = new SpanContextImp(carrier.spanId, carrier.traceId, sampled);

  if (carrier.baggage) {
    const { baggage } = carrier;
    Object.keys(baggage).forEach(key => {
      context.setBaggageItem(key, baggage[key]);
    });
  }

  return context;
};

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
export const injectIntoTraceCarrierObject = (
  spanContext: SpanContextImp,
  carrier: { [propKey: string]: any }
): void => {
  if (!carrier || typeof carrier !== "object") return;

  carrier.spanId = spanContext._guid;
  carrier.traceId = spanContext._traceGUID;
  carrier.sampled = true;
  carrier.baggage = {};
  spanContext.forEachBaggageItem((key: string, value: any) => {
    carrier.baggage[key] = value;
  });
};
/* eslint-enable no-param-reassign */
/* eslint-enable no-underscore-dangle */

/**
 * Create and return span context based
 * on span data injected from server
 */
export const getServerSpanContext = (
  name: "portalSpan" | "rootSpan"
): SpanContextImp | undefined => {
  let portalConfig: PortalConfig | null = null;
  try {
    portalConfig = getPortalConfig();
  } catch (e) {}

  const { tracing } = portalConfig?.monitoring || {};
  if (!tracing || !tracing[name]) {
    return undefined;
  }

  const context = tracing[name];

  return extractFromTraceCarrierObject({
    ...context,
    baggage: tracing.baggage
  });
};
