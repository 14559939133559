import React, { useMemo, useEffect } from "react";
import { NavigationItemConfig } from "@zeos/platform";
import {
  Box,
  Link,
  NavDrawer,
  VerticalMenu,
  Breadcrumbs
} from "@zeos/components";
import { navigateTo } from "../util/routing";
import NavBarAccount from "./NavBarAccount";
import { AlertDialog } from "./AlertDialog";
import { SnackbarList } from "./SnackbarList";
import { TopBar } from "./TopBar";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";
import ZeosLogo from "/assets/zeos/zeos.svg";
import { useSidebarNavigation } from "./hooks/useSidebarNavigation";

interface Props {
  navigation: NavigationItemConfig[];
}

const findSelectedPrimaryNav = (
  tabs: NavigationItemConfig[],
  activeRoute: string
) =>
  tabs.find(({ children, href }) =>
    activeRoute.startsWith(href) ||
    children?.some(({ href }) => activeRoute.startsWith(href))
  );

const findSelectedNavItem = (
  activeRoute: string,
  tab?: NavigationItemConfig
) => {
  if (!tab?.children) {
    return tab;
  }
  return tab?.children?.find(({ href }) => activeRoute.startsWith(href))
};

const NAVBAR_OPEN_WIDTH = 248;

const Dashboard = ({
  navigation,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const [activeRoute, setActiveRoute] = React.useState("");
  const menuItems = useSidebarNavigation(navigation);

  const currentNav = useMemo(() => {
    return findSelectedPrimaryNav(navigation, activeRoute);
  }, [navigation, activeRoute]);

  const selectedNav = useMemo(() => {
    return findSelectedNavItem(activeRoute, currentNav);
  }, [currentNav, activeRoute]);

  const breadcrumbs = useBreadcrumbs({ currentNav, selectedNav });
  const homeHref = useMemo(() =>  navigation?.[0]?.href ?? "/", [navigation]);

  useEffect(() => {
    const handleRouteChange = () => {
      setActiveRoute(window.location.pathname);
    };

    handleRouteChange();
    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const onBreadcrumbClick = (
    link: string,
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    navigateTo(link);
  }

  return (
    <Box display="flex" flexDirection="row">
      <NavDrawer openWidth={NAVBAR_OPEN_WIDTH} open>
        <Link
          sx={{
            marginBottom: ({ spacing }) => spacing(5),
            padding: "0"
          }}
          onClick={() => navigateTo(homeHref)}
          underline="none"
        >
          <img src={ZeosLogo} alt="zeos" />
        </Link>
        <VerticalMenu menuItems={menuItems} selectedItemId={selectedNav?.id} />
      </NavDrawer>
      <AlertDialog />
      <SnackbarList />
      {/* TODO REMOVE MINWDITH ONCE RESPONSIVENESS IS IN PLACE */}
      <Box display="flex" flexDirection="column" flexGrow={1} minWidth={0}>
        <TopBar>
          <Breadcrumbs onClick={onBreadcrumbClick} items={breadcrumbs}/>
          <NavBarAccount isAccountSelectAllowed={true} />
        </TopBar>
        <Box overflow="auto" maxWidth={`calc(100vw - ${NAVBAR_OPEN_WIDTH}px)`}>
          <Box flexGrow={1} overflow="auto" minWidth={1168}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
