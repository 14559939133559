import { Lifecycles } from "single-spa-react";
import { ParcelAbortError, ParcelLoadError } from "./parcel-errors";
import { PortalAppManifest } from "@zeos/platform";
import { getOverride } from "../importOverrides";

export const loadParcel = async (
  manifest: PortalAppManifest,
  signal?: AbortSignal
): Promise<Lifecycles> => {
  if (!window.System) throw new Error("SystemJS does not exist");

  if (signal?.aborted)
    throw new ParcelAbortError("Failed before loading parcel: Signal aborted!");

  try {
    const existingOverride = getOverride(manifest.moduleName);

    const jsPath = existingOverride ?? manifest?.files?.js;
    return await window.System.import(jsPath);
  } catch (error: any) {
    if (error.name === ParcelAbortError.name) throw error;
    throw new ParcelLoadError(error.message);
  }
};
