import React from "react";
import { Provider } from "react-redux";
import { TrackingContext } from "@zeos/components";

import store from "core/store";
import { getPortalConfig } from "core/portal-config";
import { ZeosThemeProvider } from "@zeos/theme";
import Dashboard from "components/Dashboard";
import { themeOverrides } from "src/template-system/theme";

/**
 * Root template
 *
 * Renders navigation bar and empty divs
 * for each application
 */
export const RootTemplate = (): React.ReactElement => {
  const { apps, navigation } = getPortalConfig();

  return (
    <Provider store={store}>
      <ZeosThemeProvider theme={themeOverrides}>
        <TrackingContext>
          <Dashboard navigation={navigation.tabs}>
            {apps.map(app => (
              <div id={app.name} key={app.name} />
            ))}
          </Dashboard>
        </TrackingContext>
      </ZeosThemeProvider>
    </Provider>
  );
};
