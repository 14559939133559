import { registerApplication, addErrorHandler } from "single-spa";
import type {
  Me,
  PortalConfig,
  BootstrapProps,
  PortalAppManifest
} from "@zeos/platform";

import store from "core/store";
import { createPortalApps } from "core/portal-app";
import { generateI18NextConfig } from "core/i18n";
import { trackingEventSchema } from "core/tracking-schema";
import { getRootSpan, getTracer } from "core/monitoring/tracing";
import { captureError } from "@zeos/platform";
import { getOverride } from "./importOverrides";

/**
 * Register Single-SPA apps both local (apps owned by the portal itself)
 * and the one provided by the teams (portal apps owned by other teams and loaded from different remote locations)
 *
 * @param {PortalConfig} portalConfig - portal user configuration, a subset of this data will be passed in CustomProps to every application
 * @param {Object} [customProps] - custom properties that are passed to single-spa application life-cycle methods  (see https://github.com/CanopyTax/single-spa/blob/master/docs/applications.md#passing-custom-properties-to-applications)
 * @return {Promise} - resolve with no value if everything is loaded and registered or reject with if any fatal error happened
 */
export async function register(
  portalConfig: PortalConfig,
  me: Me
): Promise<void> {
  addErrorHandler(error => {
    const { appOrParcelName } = error;
    const isApplicationOverriden = Boolean(getOverride(appOrParcelName));

    if (isApplicationOverriden) {
      console.error(
        `Error in application "${appOrParcelName}" was caught, but the application is overriden, so it will not be reported to Sentry. `,
        { error }
      );
      return;
    }

    captureError(error, {
      tags: { severity: "critical" },
      appName: error.appOrParcelName
    });
  });

  const portalApps = createPortalApps(portalConfig.apps);

  const tracer = getTracer("web_portal");

  portalApps.forEach(({ applicationOrLoadingFn, activityFn, ...app }) => {
    const portalAppCustomProps = {
      getI18NextConfig: (name?: string, manifest?: PortalAppManifest) =>
        generateI18NextConfig(name || app.name, manifest || app.manifest)
    };

    const bootstrapProps: BootstrapProps = {
      // add appName as injected prop to be backward-compatible with single-spa 4.x
      appName: app.name,
      context: {
        store,
        me,
        app
      },
      portalConfig,
      trackingEventSchema,
      tracingContext: {
        rootSpan: getRootSpan(tracer),
        tracer
      },
      // @ts-expect-error - createSentryClient is not defined in the PortalConfig, we pass it for for backward compatibility with zdirect sdk
      createSentryClient: () => undefined,
      // add customProps key to be backward-compatible with single-spa 4.x
      customProps: portalAppCustomProps,
      domElementGetter: () => document.getElementById(app.name)
    };

    registerApplication(
      app.name,
      applicationOrLoadingFn,
      activityFn,
      bootstrapProps
    );
  });
}
