import { getPortalConfig, PortalConfig } from "../../portal-config";

/**
 * Check if all the performance API features
 * exist in the browser
 */
export const hasPerformanceApi = (): boolean =>
  Boolean(
    window.performance &&
      window.performance.timeOrigin &&
      // @ts-ignore
      window.performance.getEntriesByType &&
      window.PerformanceObserver
  );

/**
 * Get current time in microseconds
 *
 * This function returns high resolution time if performance api
 * exists. Otherwise, it returns unix timestamp of current time.
 */
export const getCurrentTime = (): number => {
  if (hasPerformanceApi()) {
    return (window.performance.timeOrigin + window.performance.now()) * 1000;
  }

  return Date.now() * 1000;
};

/**
 * Converts relative time to absolute time based on server
 *
 * This function adds server time to passed time to convert
 * it to absolute time that is based on server. This function
 * is useful for synchronizing state
 *
 * @param relativeTime
 */
export const convertRelativeTimeToServerTime = (
  relativeTime: number
): number => {
  let portalConfig: PortalConfig | null = null;
  try {
    portalConfig = getPortalConfig();
  } catch (e) {}
  const { tracing } = (portalConfig || {}).monitoring || {};
  if (!tracing || !(tracing.serverTimeOrigin > 0))
    return Math.floor(relativeTime);

  return Math.floor(tracing.serverTimeOrigin + relativeTime);
};

/**
 * Convert span time to be based on server absolute time
 *
 * This function first converts the span to relative using
 * performance api time origin, then converts it to absolute time
 * based on server time
 *
 * @param spanTime Absolute Span Time in Microseconds
 * @return Absolute Span time in microseconds based on server time
 */
export const convertSpanTimeOriginToServer = (spanTime: number): number => {
  let portalConfig: PortalConfig | null = null;
  try {
    portalConfig = getPortalConfig();
  } catch (e) {}
  const { tracing } = (portalConfig || {}).monitoring || {};
  if (!tracing || !(tracing.serverTimeOrigin > 0)) return Math.floor(spanTime);

  const timeOrigin = hasPerformanceApi()
    ? window.performance.timeOrigin
    : window.loadStartTime;

  return Math.floor(spanTime - timeOrigin * 1000 + tracing.serverTimeOrigin);
};
