import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { navigateTo } from "util/routing";
import type { NavigationItemConfig } from "@zeos/platform";
import { getMenuItemIcon } from "util/app-icons";

export const useSidebarNavigation = (navigation: NavigationItemConfig[] = []) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const buildNavItem = (navItem: NavigationItemConfig) => ({
      ...navItem,
      icon: navItem?.icon && getMenuItemIcon(navItem.icon),
      label: t(navItem.label),
      onClick: () => navigateTo(navItem.href),
    });
    return navigation.map((menuItem) => ({
      primary: buildNavItem(menuItem),
      secondary: menuItem.children?.map(child => buildNavItem(child)),
    }));
  }, [navigation]);
}
