import { getMe, PortalAppManifest } from "@zeos/platform";
import { mountRootParcel } from "single-spa";
import { generateI18NextConfig } from "../i18n";
import { getPortalConfig } from "../portal-config";
import store from "../store";
import { fetchManifest } from "./fetch-manifest";
import { loadParcel } from "./load-parcel";
import parcelCache from "./parcel-cache";
import { trackingEventSchema } from "core/tracking-schema";
import { getRootSpan, getTracer } from "../monitoring/tracing";
import { decorateLifecycles } from "./lifecycle-decorators";
import { closeParcel } from "./close-parcel";
import { ParcelAbortError } from "./parcel-errors";

/**
 * Custom parcel properties
 */
interface ParcelProps {
  /**
   * @deprecated customProps is only needed for
   *             backwards compatibility and should
   *             not be used to pass data to apps,
   *             Everything can just be provided to
   *             the parcel props directly.
   */
  customProps?: Record<string, unknown>;

  [propName: string]: any;
}

export type OpenParcelOptions = {
  root: HTMLElement;
  initiator: string;
  props?: ParcelProps;
  signal?: AbortSignal;
};

export interface ParcelsInterface {
  /**
   * open parcel
   *
   * @param eventName
   * @param calback
   */
  openParcel(eventName: string, options: OpenParcelOptions): Promise<void>;

  /**
   * close parcel
   *
   * @param eventName
   */
  closeParcel(eventName: string): Promise<void>;
}

/**
 * Creates parcel functions with
 * initiator attached to it
 *
 * @param initiator Create initiators for parcel functions
 * @returns Object that contains parcel functions
 */
export const createParcelsWithInitiator = (
  initiator: string
): ParcelsInterface => {
  return {
    openParcel: (childName: string, options: OpenParcelOptions) => {
      return openParcel(childName, {
        ...options,
        // The initiator will always be overriden to ensure that
        // this cannot be changed.
        initiator
      });
    },
    closeParcel
  };
};

/**
 * Open parcel
 *
 * Load manifest, load parcel module, and
 * mount it to the provided root element
 *
 * @param name Parcel name
 * @param root Element to mount the parcel
 * @param params Custom props for parcel
 * @return {Promise resolve} Parcel mounted
 * @return {Promise reject} Parcel failed to mount
 *
 * @notes Error and success states are provided by the
 *        promise; so, there are no custom callbacks
 *        for mount and errors.
 * @example Example usage with loading:
 *
 * try {
 *   setError(null);
 *   setLoading(true);
 *   await openParcel('template', document.getElementById('template')!);
 *   setLoading(false);
 * } catch (e) {
 *   setError(e);
 * } finally {
 *   setLoading(false);
 * }
 */
export const openParcel = async (
  name: string,
  options: OpenParcelOptions
): Promise<void> => {
  if (options.signal?.aborted)
    throw new ParcelAbortError("Failed to open parcel: Signal aborted!");

  const tracer = getTracer("web_portal");

  const parcel = parcelCache.getParcelData(name);

  const { root, initiator, props } = options;
  const { config, manifest: cachedManifest } = parcel;

  const parcelManifest =
    cachedManifest || (await fetchManifest(config.url, options.signal));
  const parcelLifecycles = await loadParcel(parcelManifest, options.signal);

  const decoratedLifecycles = decorateLifecycles(
    name,
    parcelLifecycles,
    tracer,
    parcel.hasBeenBootstrapped
  );

  const app = {
    name: config.name,
    type: config.type,
    label: config.label,
    pathPrefix: config.pathPrefix,
    manifest: parcelManifest
  };
  // mountRootParcel requires customProps that have a property called domElement that is a DOMElement.
  const customProps = {
    ...props,
    parcels: createParcelsWithInitiator(name),
    // will check with the team to see the impact of deprecating customProps
    customProps: {
      ...props?.customProps,
      me: getMe(),
      getI18NextConfig: (name: string, manifest: PortalAppManifest) =>
        generateI18NextConfig(name || config.name, manifest || parcelManifest),

      // @compatibility domElement in custom props is needed
      // for single-spa-react's olders to work with parcels.
      // This issue is resolved in v3.0.1
      // @source https://github.com/single-spa/single-spa-react/compare/v3.0.0...v3.0.1
      domElement: root
    },
    context: {
      me: getMe(),
      store,
      app
    },
    trackingEventSchema,
    domElement: root,
    appName: name,
    name,
    portalConfig: getPortalConfig(),
    // Note: Not sure how tracingContext is going to work
    // with nested parcels; so, requires investigation.
    tracingContext: {
      rootSpan: getRootSpan(tracer),
      tracer
    },
    createSentryClient: () => undefined
  };

  const singleSpaParcel = mountRootParcel(decoratedLifecycles, customProps);

  parcelCache.setParcelData(name, {
    ...parcel,
    manifest: parcelManifest,
    initiator,
    singleSpaParcel
  });

  // beside throwing from mountRootParcel, the same error will be thrown from bootstrapPromise or mountPromise
  // we're handling it with a catch case to prevent uncaught error
  // we don't throw our custom error because nodejs will complain when throwing error in async function
  // if we want to handle error by with portal toastbox, here's the place
  singleSpaParcel.bootstrapPromise
    .then(value => {
      // when parcel is bootstrapped successfully, we update parcel
      // cache that updates current parcel bootstrap flag so it will
      // not bootstrap the parcel again
      parcelCache.setParcelAsBootstrapped(name);

      return value;
    })
    .catch(err => {
      console.log(err);
    });
  singleSpaParcel.mountPromise.catch(err => {
    console.log(err);
  });

  if (options.signal?.aborted)
    throw new ParcelAbortError(
      "Failed before bootstrap parcel: Signal aborted!"
    );
  // resolve once the parcel has been bootstrapped
  await singleSpaParcel.bootstrapPromise;

  if (options.signal?.aborted)
    throw new ParcelAbortError("Failed before mount parcel: Signal aborted!");
  // resolve once a parcel has been appended to the DOM
  await singleSpaParcel.mountPromise;
};
