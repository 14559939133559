import { InitOptions, InterpolationOptions } from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { i18nInit, PortalAppManifest } from "@zeos/platform";

import { getPortalConfig, I18NextConfig } from "core/portal-config";

import FormatterFactory from "core/i18n/FormatterFactory";
import { getOverride } from "../importOverrides";

const formatterFactoryInstance = new FormatterFactory();

export const DEFAULT_LNG = "en";
export const DEFAULT_NS = "common";
export const PORTAL_NS = "portal";

/**
 * Helper function to determine if debug should be enabled
 *
 * @param i18next i18Next config
 * @returns true if debug is enabled
 */
export const isI18NextDebugEnabled = (i18next: I18NextConfig): boolean => {
  if (i18next && "debug" in i18next) {
    return i18next.debug;
  }

  return false;
};

declare module "i18next" {
  interface InterpolationOptions {
    alwaysFormat?: boolean;
  }
}

export const interpolationHandler = (): InterpolationOptions => ({
  format: (value, format = "") => {
    return formatterFactoryInstance.format(value, format);
  },
  alwaysFormat: true,
  // it escapes / by default which we need for dates like 12/23/2020
  // https://www.i18next.com/translation-function/interpolation#unescape
  escapeValue: false
});

const getBaseUrl = () => getPortalConfig().i18next.localesBaseUrl;

const defaultI18NextConfig = {
  fallbackLng: DEFAULT_LNG,
  lng: DEFAULT_LNG,
  debug: true,
  ns: [DEFAULT_NS, PORTAL_NS], // array that contains ['common', 'portal']
  defaultNS: PORTAL_NS, // load portal namespace as default one
  fallbackNS: DEFAULT_NS, // use common namespace as fallback
  // https://github.com/i18next/i18next-xhr-backend
  backend: {
    loadPath: (lng: string, ns: string) => `${getBaseUrl()}/${ns}_${lng}.json`
  },
  interpolation: interpolationHandler()
};

export const portalI18NextConfig = (i18next: I18NextConfig): InitOptions => ({
  ...defaultI18NextConfig,
  debug: isI18NextDebugEnabled(i18next)
});

export const generateLoadPath =
  (moduleName: string, fileName: string, appTranslationPath?: string) =>
  (lng: string, ns: string[]): string => {
    // load module app local translation when namespace is the app name
    if (moduleName === ns[0]) {
      return `${
        getOverride(moduleName)?.replace(/\/[^/]+$/, "") || appTranslationPath
      }/locales/${fileName}_${lng}.json`;
    }

    // common translation serve as a fallback in the base of key not found and is being serve from web portal domain
    return `${getBaseUrl()}/${DEFAULT_NS}_${lng}.json`;
  };

export const generateI18NextConfig = (
  fileName: string,
  manifest: PortalAppManifest,
  options: Record<string, any> = {}
): InitOptions => {
  const { i18next } = getPortalConfig();
  const { moduleName } = manifest;
  const ns = [DEFAULT_NS]; // initialize namespaces with ${DEFAULT_NS} translation files
  let defaultNS = DEFAULT_NS; // set ${DEFAULT_NS} translation files as default namespace
  if (manifest.files && manifest.files.i18n) {
    ns.push(moduleName); // update namespaces by adding module name as namespace
    defaultNS = moduleName; // set module name as default namespace for i18next
  } else {
    console.warn(
      `i18n is missing: cannot find "${moduleName}.manifest.files.i18n" property and i18n will load "${DEFAULT_NS}" namespace as default`
    );
  }

  return {
    ...defaultI18NextConfig,
    debug: isI18NextDebugEnabled(i18next),
    ns, // array that contains ${DEFAULT_NS} and app name if exists
    defaultNS, // load default namespace whether app name if exists or ${DEFAULT_NS}
    // https://github.com/i18next/i18next-xhr-backend
    backend: {
      loadPath: (lng: string, ns: string[]) =>
        generateLoadPath(moduleName, fileName, manifest.files.i18n)(lng, ns)
    },
    ...options
  };
};

export const startI18next = async (config: I18NextConfig): Promise<void> => {
  await i18nInit(portalI18NextConfig(config), [Backend, initReactI18next]);
};
