import { start } from "single-spa";
import store from "core/store";

import { PortalApp, AppError, Me } from "@zeos/platform";
import { getCurrentHub } from "@sentry/hub";

import { register } from "core/single-spa";
import { startI18next } from "core/i18n";

import { getPortalConfig, getAuthErrorType } from "core/portal-config";
import { startAppcues, startHotjar, startGTM } from "core/tracking";
import { startTracing, setMeForTracing } from "core/monitoring/tracing";
import { initializeSDK } from "core/sdk";
import { redirectHandler } from "core/redirect-handler";

import { appLoadingFailed } from "core/store/app-status/app-status.actions";
import { mountRootTemplate, mountStatusTemplate } from "../template-system";
import { captureError } from "@zeos/platform";
import { startSentryForPortal } from "core/sentry";

/**
 * Returns App Manifest for Web Portal
 *
 * This value is static and is only used for
 * data collection (e.g Google Analytics)
 *
 * @VisibleForTesting
 */
export const getPortalAppManifest = (): PortalApp => ({
  name: "zeos-one",
  type: "application",
  label: "Web Portal",
  pathPrefix: "/",
  manifest: {
    moduleName: "portal-app",
    buildVersion: "systemjs",
    files: {
      js: ""
    }
  }
});

export const bootstrap = async (me: Me): Promise<void> => {
  // mount status app at the beginning to show the loader
  // while initializing portal and apps
  mountStatusTemplate();
  redirectHandler();
  const app = getPortalAppManifest();
  const portalConfig = getPortalConfig();

  startSentryForPortal(portalConfig.sentry);

  try {
    // This function handles all exceptions
    await startI18next(portalConfig.i18next);
    const authErrorType = getAuthErrorType();

    if (authErrorType) {
      await startTracing();

      throw new AppError("", {
        type: authErrorType
      });
    }

    setMeForTracing(me);

    initializeSDK(portalConfig, me, app);

    startAppcues(portalConfig.appcues, me);

    // we do not want hotjar loading to block bootstrap
    startHotjar(portalConfig.hotjar);

    // we do not want GTM to block bootstrap
    startGTM(portalConfig.googleAnalytics, me, app);

    // mount the root template
    mountRootTemplate();

    // register portal apps using single-spa and passdown me object
    await register(portalConfig, me);

    // must be called by single spa config after apps are registered
    // https://single-spa.js.org/docs/api/#start
    start();

    await startTracing();
  } catch (error: any) {
    console.error(`Failed to bootstrap portal: ${error.message}`);

    store.dispatch(appLoadingFailed("zeos-one", "ZEOS One Portal", error));

    // use default hint, and use current scope add extra data
    captureError(error, getCurrentHub().getScope());
  }
};
