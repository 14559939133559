import type{ NavigationItemConfig } from "@zeos/platform-types";
import { AppConfig } from "@zeos/platform";
import { RawNavigationItemConfig } from "typings";

export const filterNavigationItems = (
  navigation: RawNavigationItemConfig[] = [],
  enabledApps: AppConfig[] = [],
): NavigationItemConfig[] => {
  return navigation.reduce<NavigationItemConfig[]>((accu, item) => {
    if (item.children) {
      const children = filterNavigationItems(item.children, enabledApps);
      if (children.length) {
        const menuItem = {
          ...item,
          href: children[0].href,
          children,
        }
        return [
          ...accu,
          menuItem,
        ];
      }
    }
    const isEnabledApp = enabledApps.some((app) => app.name === item.id);
    if (isEnabledApp) {
      return [
        ...accu,
        item,
      ];
    }
    return accu;
  }, []);
}
