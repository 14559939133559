import "systemjs";
import "systemjs/dist/extras/amd";
import "systemjs/dist/extras/named-register";
import "import-map-overrides";
import { AppConfig, sentry, Toggle } from "@zeos/platform";
import { bootstrap } from "core/bootstrap";
import { initAuth } from "core/auth";
import { appendImportMap } from "util/import-map";
import { fetchMe } from "core/api";
import { filterByAccess } from "access-filtering/access-filtering";
import type{ ParcelConfig } from "@zeos/platform-types";
import { filterNavigationItems } from "access-filtering/navigation-filtering";

const getFeatureToggleConfig = async () => {
  const response = await fetch("/api/portals/fulfillment-b2b-portal/enabled-feature-toggles");
  return await response.json();
};

// TODO move to platform package
async function loadConfiguration() {
  const [
    toggles,
    localConfig,
    me,
  ] = await Promise.all([
    getFeatureToggleConfig(),
    import('./config'),
    fetchMe(),
  ]);

  const enabledFeatureToggles = toggles.enabled_features as Toggle[];

  const apps = filterByAccess<AppConfig>(
    localConfig.config.apps,
    enabledFeatureToggles,
    me.scopes,
  );
  const parcels = filterByAccess<ParcelConfig>(
    localConfig.config.parcels,
    enabledFeatureToggles,
    me.scopes,
  );
  const navigation = filterNavigationItems(
    localConfig.config.navigation,
    apps,
  );

  window.PortalConfig = {
    ...localConfig.config,
    navigation: { tabs: navigation },
    enabledFeatureToggles,
    apps,
    parcels,
  };
  appendImportMap([
    ...apps,
    ...parcels,
  ]);

  return me;
}

initAuth(() => loadConfiguration().then(bootstrap)).catch(error => {
  console.error("Error initializing shell", error);
  sentry.captureError(error);
});
