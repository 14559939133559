import React from "react";
import Parcel from "./Parcel";
import parcelCache from "../../core/parcels/parcel-cache";

interface ParcelProps {
  parcelId: string;
  elementId: string;
}

const NotificationParcel = ({
  parcelId,
  elementId
}: ParcelProps): React.ReactElement => {
  const [shouldDisplayParcel, setShouldDisplayParcel] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const isAvailable = parcelCache.isParcelAvailable(parcelId);
    setShouldDisplayParcel(isAvailable);
  }, []);

  if (shouldDisplayParcel) {
    return <Parcel parcelId={parcelId} elementId={elementId} />;
  }
  return <></>;
};

export default NotificationParcel;
